[role="tablist"] {
  @apply flex flex-row gap-3;
}

[role="menu"] {
  @apply bg-white shadow-lg border border-slate-100 min-w-48 rounded;
}

[role="menuitem"] {
  @apply block px-3 py-2 text-sm font-normal no-underline shadow-none cursor-pointer data-[focus]:bg-slate-100;
}

/* React Select */
.css-13cymwt-control {
  @apply !border-gray-200;
}

/* focus */
.css-t3ipsp-control {
  @apply ring-1 ring-slate-300 !border-slate-300  !shadow-none;
}

/* option hovered, selected */
.css-d7l1ni-option {
  @apply !bg-slate-100;
}

/* selected */ 
.css-tr4s17-option {
  @apply !bg-blue-700;
}

.css-tr4s17-option > * {
  @apply !text-white;
}

/* option item */
.css-d7l1ni-option,
.css-tr4s17-option,
.css-10wo9uf-option {
  @apply !text-[15px];
}

/* placeholder */
.css-1jqq78o-placeholder {
  @apply !text-gray-400;
}

/* select menu inside a dialog */
[role="dialog"] .css-1nmdiq5-menu {
  @apply !z-50;
}

/* React Datepicker */
.react-datepicker {
  @apply border-slate-200 shadow-lg;
}

.react-datepicker-popper .react-datepicker__triangle {
  @apply stroke-slate-200;
}

.react-datepicker__header {
  @apply bg-gray-100 border-slate-200;
}

.react-datepicker__navigation {
  @apply shadow-none;
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #cbd5e1 #f1f5f9;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 15px;
}

*::-webkit-scrollbar-track {
  @apply rounded-sm;
  background-color: #f1f5f9;
}

*::-webkit-scrollbar-thumb {
  @apply rounded;
  background-color: #cbd5e1;
}

/* React select country, state, city */
.stsearch-box {
  @apply relative;
}

.stsearch-box input {
  @apply w-full;
}

.stdropdown-tools {
  @apply absolute right-2 top-3;
}

.stdropdown-tools svg {
  @apply fill-slate-500;
}

.stdropdown-menu {
  @apply absolute translate-y-1 bg-white shadow-lg border border-slate-100 min-w-48 rounded max-h-40 overflow-auto z-10;
}

.stdropdown-item {
  @apply flex gap-1 items-center px-3 py-2 text-[15px] font-normal no-underline shadow-none cursor-pointer hover:bg-slate-100;
}

/* React Datepicker */
.react-datepicker {
  @apply border-slate-200 shadow-lg;
}

.react-datepicker-popper .react-datepicker__triangle {
  @apply stroke-slate-200;
}

.react-datepicker__header {
  @apply bg-gray-100 border-slate-200;
}

.react-datepicker__navigation {
  @apply shadow-none;
}

.react-datepicker__day--selected {
  @apply bg-blue-700 text-white hover:bg-blue-700;
}

.react-datepicker__day--today {
  @apply bg-slate-300 text-slate-500 hover:bg-slate-300;
}

.StripeElement {
  @apply px-3 py-3 bg-white border rounded-md;
}