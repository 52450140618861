.field {
  @apply flex flex-col gap-1;
}

.input-sm {
  @apply py-1 px-3 text-xs rounded;
}

.checkbox {
  @apply inline-block size-4 rounded border bg-white data-[checked]:bg-blue-500 data-[checked]:border-blue-500 data-[disabled]:bg-slate-100 data-[indeterminate]:bg-blue-500 data-[indeterminate]:border-blue-500;
}

.checkbox[data-checked] {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='-0.5 -0.5 10 10' height='10' width='10'%3E%3Cpath fill='%23fff' fill-rule='evenodd' d='M8.767.77a.643.643 0 0 1 .086.905L3.684 7.93l-.002.002a1.236 1.236 0 0 1-.964.445 1.236 1.236 0 0 1-.964-.48v-.002L.135 5.815a.643.643 0 1 1 1.014-.79l1.584 2.036L7.861.856A.643.643 0 0 1 8.766.77Z' clip-rule='evenodd'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
}

.checkbox[data-indeterminate] {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-0.5 -0.5 12 12' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' id='Minus--Streamline-Tabler'%3E%3Cpath d='M2.2916666666666665 5.5l6.416666666666666 0' stroke-width='1'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
}

.checkbox[data-disabled][data-checked] {
  @apply bg-blue-500/50 border-transparent;
}

.checkbox[data-disabled] + label {
  @apply text-slate-500;
}

.radio {
  @apply flex size-5 items-center justify-center rounded-full border bg-white data-[checked]:bg-blue-500 data-[disabled]:bg-slate-100;
}

.radio[data-disabled] + label {
  @apply text-slate-500;
}

.radio[data-disabled][data-checked] {
  @apply bg-blue-500/50;
}

.radio[data-checked]::after {
  @apply content-[""] inline size-2 rounded-full bg-white;
}

button[role="switch"] {
  @apply relative flex h-6 w-11 items-center rounded-full bg-gray-200 transition data-[checked]:bg-blue-700;
}

button[role="switch"]::after {
  @apply absolute content-[""] inline-block size-4 rounded-full bg-white transition -translate-x-5;
}

button[role="switch"][data-checked]::after {
  @apply translate-x-1;
}
