@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply text-neutral-700 bg-gray-100;
  }

  a, .link {
    @apply cursor-pointer font-medium text-green-700;
  }

  button, input, textarea {
    @apply py-2 rounded text-[15px];
  }

  button {
    @apply px-4 font-medium shadow;
  }

  button[role="tab"] {
    @apply py-1.5 px-4 rounded-full shadow-none bg-gray-200/50 text-slate-500 hover:bg-gray-200 border data-[selected]:bg-green-50 data-[selected]:text-green-700 data-[selected]:border-green-700/20 data-[selected]:outline-none;
  }

  .field > label {
    @apply font-medium;
  }

  input, textarea {
    @apply px-4 border focus:outline-slate-300;
  }

  select {
    @apply block rounded border border-s-gray-100 bg-white/5 py-1.5 px-3 text-sm/6 focus:outline-slate-300;
  }

  th {
    @apply font-medium text-left text-slate-500 text-sm uppercase;
  }
}

@layer components {
  @import './styles/button.css';
  @import './styles/form.css';
  @import './styles/others.css';
}